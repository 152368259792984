import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import ContentImage from './content-image'
import Image from './image'

const GlobalComponents = {
  Badge,
  Button,
  Card,
  CardBody: Card.Body,
  CardHeader: Card.Header,
  Col,
  Container,
  ContentImage,
  Image,
  Row,
}

export default function MdxRender({ children }) {
  return (
    <div className="mdx-content">
      <MDXProvider components={GlobalComponents}>{children}</MDXProvider>
    </div>
  )
}
