import React from 'react'
import cnames from 'classnames'

import Card from 'react-bootstrap/Card'

import Image from './image'

export default function WorkPoster({ caption, credit, imagePath, wrapperClasses }) {
  return (
    <Card className={cnames(wrapperClasses)}>
      <Card.Body className="p-0">
        <Image imagePath={imagePath} alt={caption} />
      </Card.Body>
      {credit && (
        <Card.Footer className="small text-end text-muted">
          {`${credit.label ?? 'Image Credit'}: ${credit.name}`}
        </Card.Footer>
      )}
    </Card>
  )
}
